import styled from '@emotion/styled'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

export interface Props {
  title: string
}

export const FavoriteParagraph = memo(function FavoriteParagraph({
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        <Fade bottom distance="3.75rem">
          <Title>{title}</Title>
        </Fade>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  padding: 0 10vw;

  @media (max-width: 1199px) {
    padding: 0 8.454vw;
  }
`

const Wrapper = styled.div`
  max-width: 600px;
  background: url('/hearts.svg') no-repeat center;
  background-size: contain;
  margin: auto;
  padding: 9rem 0 15rem;

  @media (max-width: 767px) {
    padding: 5.625rem 0;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.75rem;
  font-weight: 200;
  line-height: 4.5rem;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 2.1875rem;
    line-height: 2.625rem;
  }
`
